const firebaseConfig = {
    apiKey: "AIzaSyAk0OeZ7E4DM1TouV-ZUlUJcgK8MIAn9oM",
    authDomain: "fpmc-dev.firebaseapp.com",
    projectId: "fpmc-dev",
    storageBucket: "fpmc-dev.appspot.com",
    messagingSenderId: "518295271692",
    appId: "1:518295271692:web:9e38e6bf12950d796a3129",
    measurementId: "G-2KJ4FPMWXW"
  };

  export default firebaseConfig;
